/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/reset.scss"
import "./src/styles/roboto-condensed.scss"
import "./src/styles/roboto-mono.scss"
import "./src/styles/custom.scss"
